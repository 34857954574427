.a {
    min-height: 100vh;
    display: flex;
    align-items: center;
  }
  
.a_left {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100%;
}

.a_right {
    flex: 1;
    width: 80%;
    padding-right: 20px;
}

.a_card.bg {
    position: absolute;
    top: 50px;
    left: 50px;
    background-image: url("../img/apply-bg.webp");
    border-radius: 60px;
    background-size: cover;
    //background-color: #333;
}

.a_card {
    width: 60%;
    height: 70vh;
    border-radius: 30px;
    position: relative;
    overflow: hidden;
}

.a_card:hover {
    transform: scale(1.1);
    transition: 0.6s;
    box-shadow: 2px 2px 4px #012158;
}

.a_img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}





.a_sub {
    margin-x: 20px;
    
    
}

.a_desc {
    font-weight: 300;
    margin-top: 1rem;
}

.a_desc2 {
    font-weight: 300;
    margin-top: 1rem;
    font-style: italic;
}

.a_award_desc {
    font-weight: 300;
    margin-top: 1rem;
    font-style: italic;
}


.c_icon {
    width: 50px;
    height: 50px;
    margin-left: 20px;
    margin-bottom: 0;
}




@media screen and (max-width: 768px) {
    .a {
        flex-direction: column;
        text-align: center;
        margin-top: 50px;
    }

    .a_left {
        width: 100%;

    }

    .a_card {
        height: 40vh;
        width: 80%;
    }

    .a_card.bg, .a_award {
        display: none;
    }

    .a_img {
        width: 100%;
        min-height: 100%;
        display: absolute;
        
       
    }

    .a_right {
        padding: 20px;
    }
}