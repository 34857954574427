.pl {
    min-height: 100vh;
    padding: 50px 100px;
    display: flex; 
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

}

.pl_texts {
    width: 65%;
}

.pl_title {
    font-size: 50px;
    font-weight: 600;
}

.pl_desc {
    margin: 20px 0px;
}

.pl_list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    
}


    /* card Css */

    .p {
        width: 30vw;
        max-height: 60vh;
        margin: 20px 10px;
        border: 2px solid rgb(243, 242, 242);
        border-radius: 10px 10px 0px 0px;
        overflow: hidden;
        
    }
    
    .p_browser {
        height: 20px;
        background-color: #003da5;
        display: flex;
        align-items: center;
        position: sticky;
        z-index: 2;
    }
    
    .p_circle {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        margin: 3px;
        background-color: white;
    
    }
    
    .p_img {
        width: 100%;
        max-height: 60vh;
        object-fit: cover;
        transition: all 10s ease;
        
    }

    .p_img_2 {
        width: 100%;
        max-height: 60vh;
        object-fit: cover;
        transition: all 10s ease;
        
    }
    
    .p:hover .p_img {
        transform: translateY(-10%);
    }

@media screen and (max-width: 768px) {
    .pl {
        padding: 10px;
    }

    .pl_texts {
        width: 100%;
    }

    .pl_desc {
        display: none;
    }

    .pl_list {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .p {
        width: 100%;
        height: auto;
    }

    .p_img {
        width: 100%;
        height: auto;
        object-fit: cover;
        //transition: all 10s ease;
        
    }

    .p_img_2 {
        width: 100%;
        height: auto;
        object-fit: cover;
        //transition: all 10s ease;
        
    }
    
   

    
}