.c {
    min-height: 100vh;
    position: relative;
}

.c_bg {
    width: 20px;
    height: 100%;
    background-color: #dc1c2e ;
    position: absolute;
}

.c_wrapper  {
    padding: 50px;
    display: flex;
}

.c_left {
    flex: 1;
}

.c_right {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
}

.c_title {
    font-size: 30px;
    width: 90%;
}

.c_info_item {
    display: flex;
    align-items: center;
    margin: 50px 0px;
    font-weight: 400;
    width: 70%;
}

.c_icon {
    width: 30px;
    height: 30px;
    margin-right: 20px;
}

 a {
    color: #333 !important;
    text-decoration: none !important;
    
}

.c_desc {
    font-size: 24px;
    font-weight: 400;
    //justify-content: start !important; 

}


.i_wp_icon {
    display: hidden;
    width: 20px;
    height: 20px;
    margin-top: 30px;
    color: #012158;
}

form {
    margin-top: 20px;
}

input {
    width: 80%;
    height: 50px;
    border: none;
    border-bottom: 1px solid black;
    margin: 10px 0px;
    font-size: 14px;
    padding-left: 10px;
}

textarea {
    width: 80%;
    margin: 10px 0px;
    font-size: 14px;
    padding-left: 10px;
    
}

button {
    border: none;
    width: 80%;
    padding: 15px;
    background-color: #012158 ;
    color: white;
    font-weight: 500;
    cursor: pointer;
    border-radius: 12px;
}



@media screen and (max-width: 768px) {

    
    .c_wrapper {
        flex-direction: column;
        padding: 0px 50px;
    }

    .c_title {
        font-size: 24px;
    }

    .c_info_item {
        margin: 20px 0px;
        width: 100%;

    }

    .c_desc {
        display: none;
    }

    form {
        margin-top: 0px;
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
    }

    input {
        width: 100%;
        height: 40px;
        margin: 10px;
        margin-left: 0;
    }

    textarea {
        width: 100%;
    }
    

    button {
        width: 80%;
        margin-top: 1rem!important;
    }
}