@import url('https://fonts.googleapis.com/css2?family=Rampart+One&display=swap');

.f_container {
    width: 100%;
    min-height: 15vh;
    margin-top: .5vh;
    background-color: #012158;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Rampart One', cursive;
}

.f_container h3 {
    font-size: 1.1rem;
    color: #fff;
}

.f_scroll {
    display: flex;
    flex-direction: absolute;
    align-items: center;
    margin-right: 0px;
    background-color: transparent;
    width: 3rem;
}

.f  {
    text-decoration: none!important;
    color: #fff!important;
}