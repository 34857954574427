.teaser {
    min-height: 20vh;
  }
  
  .player_wrapper {
    position: relative;
    padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .react_player {
    position: absolute;
    top: 0;
    
    
  }
  
  @media screen and (max-width: 768px) {
    
  
    .player_wrapper {
       margin-top: 5rem;
    }
  }