
/* react-bootstrap Navbar */

.navbar {
    background-color: transparent;
    border: transparent !important;
    position: fixed;
    top: 0;
    width: 15%;

}

.btn {
    border: transparent !important;
}


.btn:focus {
    outline: none !important;
    box-shadow: none !important;
  }
 
.body {
    
    background-color: #fff!important;
    color: #012158!important;
    opacity: 80%;
    width: 25vw!important;
}

.menu {
    width: 25vw!important;
}



.close {
    color: #fff!important;
    justify-content: end!important;
}


.container {
    border-style: none;
    opacity: 50%;
    color: #012158;
    background-color: #fff;
}

/* react-bootstrap Navbar - end */