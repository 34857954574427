.i {
    display: flex;
    height: 100vh;
}

.logo {
    display: relative;
    
}

.logo_img {
    display: absolute;
    width: 80px;
    height: auto;
    padding-top: 20px;
    padding-left: 20px;
}



.i_left {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.i_social {
    display: flex;
    position: absolute;
    left: 100px;
    top: 40px;
    align-items: center;
    
}

.i_social_icon {
    width: 35px;
    height: 35px;
    margin-left: 10vw;
    margin-top: 5vh;
    color: #012158;
}

.i_social_icon:hover {
    transition-duration: 1s;
    font-weight: 500;
    color: #dc1c2e;
}

.i_social_wp {
    padding: 0px;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 25px;
    left: 380px;
}



.i_right {
    flex: 1;
    position: relative;
}

.i_left_wrapper {
    padding: 40px;
    height: 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.i_intro {
    font-size: 20px;
    font-weight: 400;
}

.i_name {
    font-size: 35px;
    font-weight: 500;
    color: #012158;
}

.i_desc {
    font-size: 1rem;
    font-weight: 200;
   
}

.i_title {
    height: 50px;
    overflow: hidden;
   
}

.i_title_wrapper {
    height: 100%;

    animation: move 10s ease-in-out infinite alternate;
}

@keyframes move {
    25%{
        transform: translateY(-50px);
    }
    50%{
        transform: translateY(-100px);
    }
    75%{
        transform: translateY(-150px);
    }
    100%{
        transform: translateY(-200px);
    }
}

.i_title_item {
    height: 50px;
    font-size: 30px;
    font-weight: bold;  
    color: #dc1c2e;
    display: flex;
    align-items: center;
}

.i_scroll {
    width: 30px;
    height: 30px;
    position: absolute;
    bottom: 20px;
  }

.i_img {
    max-width: 80vw;
    height: 100%;
    right: 20px;
    bottom: 0px;
    object-fit: cover;
    position: absolute;
    
    
}

.i_bg {
    clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 25% 100%, 0% 50%);
    background-color: #012158;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
}

@media screen and (max-width: 768px) {
    .i {
        flex-direction: column;
    }

    .logo {
        display: relative;
        
    }

    .logo_img {
        display: absolute;
        width: 50px;
        height: auto;
        padding-top: 20px;
        padding-left: 20px;
    }

    .i_social {
        display: flex;
        flex-direction: column;
        position: absolute;
        left: -10px;
        top: 90px;
        align-items: center;
        justify-content: between;
        
    }
    
    .i_social_icon {
        width: 35px;
        height: 35px;
        margin-left: 5vw;
        margin-top: 5vh;
    }

    div .i_social_wp {
        
        padding: 0px;
        width: 20px;
        height: 20px;
        position: absolute;
        top: 0px;
        left: 250px;
    }


    

    .i_intro {
        font-size: 22px;
        font-weight: 400;
    }

    .i_title_item {
        height: 50px;
        font-size: 25px;
        font-weight: bold;  
        color: #dc1c2e ;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .i_left_wrapper {
        padding: 10px;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: 100%;
    }

    .i_desc {
        display: none;
    }

    .i_img {
        max-width: 80vw;
        height: 110%;
        right: 20px;
        object-fit: cover;
        position: absolute;
        
    }
}


@media screen and (max-width: 480px) {

    .i_social {
        display: flex;
        flex-direction: column;
        position: absolute;
        left: -10px;
        top: 90px;
        align-items: center;
        justify-content: between;
        
    }

    .i_social_icon {
        width: 25px;
        height: 25px;
        margin-top: .5rem;
    }

    div .i_social_wp {
        
        padding: 0px;
        width: 18px;
        height: 18px;
        position: absolute;
        top: 0px;
        left: 150px;
    }

    .i_left_wrapper {
        align-items: end;
    }

    .i_intro {
        font-size: 18px;
        font-weight: 300;
    }
    
    .i_name {
        font-size: 25px;
        font-weight: 500;
        color: #012158;
    }
    
    
    
    .i_title_wrapper {
        height: 100%;
        align-items: center;
        animation: move 10s ease-in-out infinite alternate;
    }

    .i_title_item {
        height: 50px;
        font-size: 18px;
        font-weight: bold;  
        color: #dc1c2e ;
        display: flex;
        justify-content: center;
        align-items: center;
    }



}